import React, { useEffect, useState } from "react";

import StarRatings from "react-star-ratings";
import axios from "axios";
function ProductRate({ product }) {
  const [rate, setRate] = useState(0);
  const handleRateChange = (newRating) => {
    axios
      .post("https://api.foodyno.gomaplus.tech/api/rating/products/add", {
        value: newRating,
        product_id: product.id,
      })
      .then((response) => {})

      .catch((error) => {
        console.error("Error submitting rating:", error);
      });
    setRate(newRating);
  };
  return (
    <div className=" flex items-center   p-3  gap-4 mx-auto ">
      <div className="rounded-xl  hover:scale-110 transition-all duration-300 ease-in-out  overflow-hidden">
        <img
          src={"https://api.foodyno.gomaplus.tech/" + product.image}
          alt="img"
          className=" w-28 h-28  object-cover object-center"
        />
      </div>
      <div className="flex lg:flex-row flex-col items-start text-start justify-between gap-6">
        <h1
          className={`text-black font-semibold 
       text-xl `}
        >
          {product.name}
        </h1>

        <StarRatings
          rating={rate} // Use the state variable here
          changeRating={(newRating) => handleRateChange(newRating)} // Update the state on rating change
          numberOfStars={5}
          starDimension="24px"
          starSpacing="1px"
          starRatedColor="#FFC700"
          starHoverColor="#FFC700"
        />
      </div>
    </div>
  );
}

export default ProductRate;
